import { UserRejectedRequestError } from 'viem'


const isUserRejectedRequestError = (error: any): error is UserRejectedRequestError => {
  return (
    error instanceof UserRejectedRequestError
    || Boolean(error?.cause && isUserRejectedRequestError(error?.cause))
  )
}

export default isUserRejectedRequestError
